export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js")
];

export const server_loads = [];

export const dictionary = {
		"/": [~2],
		"/Pt3fzMtrHw3AKj7Clj9lI7PtIezQgCUNRt5RNJhcPHdwNRTKZPnOE4S8HiHDOmVudkbL3iI6ensBEnY95KjLrmx1pYbNDq8U6EfyoTsK0A2lVXNY78eWDFvqqexDXobl": [~3]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from "/.svelte-kit/generated/root.svelte";